import * as React from "react";
import { Row, Form, Input, Checkbox, Button } from "antd";
import { useEvent, useStore } from "effector-react";
import { compose } from "ramda";
import Head from "next/head";

import { CommonTemplate } from "ui/templates";
import { $isAuthenticated } from "features/viewer";
import { withRedirect, withPageExclusion } from "lib/next";

import { formSubmitted, $isFormSent } from "./model";

const enhance = compose(withRedirect("/events", $isAuthenticated), withPageExclusion("/home"));

function HomePage() {
  const [form] = Form.useForm();

  const isFormSent = useStore($isFormSent);
  const submitForm = useEvent(formSubmitted);

  return (
    <CommonTemplate>
      <Head>
        <title>Event Schedule | Login</title>
      </Head>
      <Row justify="center" align="middle">
        <Form
          form={form}
          onFinish={submitForm}
          labelCol={{ span: 10 }}
          initialValues={{ remember: true }}
          wrapperCol={{ span: 8, xl: { span: 4 } }}
          style={{ width: "100%", overflow: "hidden" }}
        >
          <Form.Item name="login" label="Login" rules={[{ required: true, message: "Please input your login!" }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password autoComplete="current-password" />
          </Form.Item>
          <Form.Item name="remember" valuePropName="checked" wrapperCol={{ span: 6, sm: { offset: 10 } }}>
            <Checkbox>Remember</Checkbox>
          </Form.Item>
          <Form.Item wrapperCol={{ span: 6, sm: { offset: 10 } }}>
            <Button type="primary" htmlType="submit" loading={isFormSent}>
              Sign in
            </Button>
          </Form.Item>
        </Form>
      </Row>
    </CommonTemplate>
  );
}

export default enhance(HomePage);
