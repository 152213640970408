import * as React from "react";
import { useRouter as useNextRouter } from "next/router";
import { useStore } from "effector-react";
import { NextPage } from "next/types";
import { Store } from "effector";

import { isServer } from "lib/ssr";

interface InitialPropsResult {
  redirect?: Function;
  isPageWillBeRendered?: boolean;
}

export interface WithRedirectConfig {
  code?: number;
  asUrl?: string;
  replace?: boolean;
  useRouter?: typeof useNextRouter;
}

export function withRedirect<P>(
  url: string,
  pred: Store<boolean>,
  { asUrl, replace, code = 302, useRouter = useNextRouter }: WithRedirectConfig = {},
) {
  return function (Page: NextPage<P>) {
    const WithRedirect: NextPage<P & InitialPropsResult, InitialPropsResult> = (props) => {
      const { redirect, isPageWillBeRendered = true, ...pageProps } = props;
      const passedPredicate = useStore(pred);
      const router = useRouter();

      const isRedirectNeed = isPageWillBeRendered && passedPredicate;

      React.useEffect(() => {
        if (isRedirectNeed) {
          const method = replace ? "replace" : "push";

          router[method](url, asUrl);
        }
      }, [router, isRedirectNeed]);

      /* The only way to redirect with setting the status code :(( */
      if (isServer() && isRedirectNeed && redirect) {
        redirect();

        return null;
      }

      return <Page {...(pageProps as P)} />;
    };

    WithRedirect.getInitialProps = async (ctx) => {
      let initialProps: InitialPropsResult = {};

      if (Page.getInitialProps) {
        initialProps = await Page.getInitialProps(ctx);
      }

      if (isServer()) {
        initialProps.isPageWillBeRendered = ctx.res!.statusCode < 300;

        initialProps.redirect = () => {
          ctx.res!.writeHead(code, { Location: url });
          ctx.res!.end();
        };
      }

      return initialProps;
    };

    return WithRedirect;
  };
}
