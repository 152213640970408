import { forward, createEvent, createStore } from "effector-next";
import { message } from "antd";
import { prop } from "ramda";

import { saveSession } from "features/viewer";
import { login, LoginPayload } from "api/security";

export const formSubmitted = createEvent<LoginPayload>();

export const $isFormSent = login.pending;
export const $formFailure = createStore<null | string>(null);

$formFailure.on(login, () => null);
$formFailure.on(login.failData, (_, { message: errorMessage, response }) => {
  return response?.data.message || errorMessage || null;
});

forward({
  from: formSubmitted,
  to: login,
});

forward({
  from: login.doneResult.map(prop("data")),
  to: saveSession,
});

$formFailure.watch((error) => {
  if (error !== null) {
    message.error(error);
  }
});
