import * as React from "react";
import { NextPage } from "next/types";
import { isServer } from "lib/ssr";
import ErrorPage from "next/error";

interface InitialPropsResult {
  isExcluded?: boolean;
}

export function withPageExclusion<P>(excludedUrl: string) {
  return function (Page: NextPage<P>) {
    const WithPageExclusion: NextPage<P & InitialPropsResult, InitialPropsResult> = ({ isExcluded, ...props }) => {
      let componentProps = props as P;
      let Component = Page;

      if (isExcluded) {
        Component = ErrorPage as never;
        componentProps = { statusCode: 404 } as never;
      }

      return <Component {...componentProps} />;
    };

    WithPageExclusion.getInitialProps = async (ctx) => {
      let initialProps: InitialPropsResult = {};

      if (Page.getInitialProps) {
        initialProps = await Page.getInitialProps(ctx);
      }

      if (!isServer()) {
        return initialProps;
      }

      if (ctx.req?.url === excludedUrl) {
        ctx.res!.statusCode = 404;
        initialProps.isExcluded = true;
      }

      return initialProps;
    };

    return WithPageExclusion;
  };
}
